<template>
  <div class="has-text-centered">
    <card-component
      v-if="isInCard"
      title="Error"
      icon="alert"
      :has-button-slot="true"
      :has-card-header-background="true"
    >
      <router-link slot="button" to="/" class="button is-small">
        <b-icon icon="desktop-mac" custom-size="default" />
      </router-link>

      <error-content
        title="on Maintenance"
        @go-back="goBack"
        :backButton="false"
      />
    </card-component>

    <error-content
      v-else
      title="on Maintenance"
      @go-back="goBack"
      :backButton="false"
    />
  </div>
</template>

<script>
import ErrorContent from '@/components/ErrorContent.vue'
import CardComponent from '@/components/CardComponent.vue'

export default {
  name: 'Error',
  components: { CardComponent, ErrorContent },
  props: {
    isInCard: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>
